import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalContent,
  MDBModalDialog,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { formatEvent } from "../../helpers/events";
import Jsontableify from "jsontableify";
import Parser from "html-react-parser";
import "./SimultaneousModal.css";

const SimultaneousModal = ({
  isOpen,
  setIsOpen,
  modalContent,
  modalType,
  onDeleteEvent,
  onLoadReport,
  eventDeleting,
}) => {
  const renderReportDetails = (html) => (
    <div className="modal_table_wrapper">{Parser(html)}</div>
  );

  const renderContent = (modalType) => {
    const jt = new Jsontableify();
    const content = jt.toHtml(modalContent).html;

    switch (modalType) {
      case "reportDetails":
        return renderReportDetails(content);
      case "eventDetails":
        return renderReportDetails(formatEvent(content));
      default:
        return null;
    }
  };

  return (
    <MDBModal show={isOpen} setShow={setIsOpen}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              Report: match in Duplicate submissions
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </MDBModalHeader>
          <MDBModalBody>{renderContent(modalType)}</MDBModalBody>
          <MDBModalFooter>
            {modalType === "eventDetails" && (
              <>
                <MDBBtn
                  color="danger"
                  onClick={() => onDeleteEvent()}
                  disabled={eventDeleting}
                >
                  {eventDeleting ? "Removing" : "Remove"}
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  onClick={() => onLoadReport()}
                  disabled={eventDeleting}
                >
                  Download
                </MDBBtn>
              </>
            )}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default SimultaneousModal;
