import { useMemo, useState } from "react";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import dayjs from "dayjs";
import { DuplicateManuscriptModal } from "../components/DuplicateManucsriptModal";
import SimultaneousModal from "../../../components/SimultaneousModal/SimultaneousModal";
import { getEventByPersistentId } from "../../../helpers/events";
import { WatchlistManuscriptModal } from "../components/WatchlistManuscriptModal";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { TooltipButton } from "../components/TooltipButton";
import { ReportModal } from "../../../components/ReportModal/ReportModal";
import useScreenedManuscripts from "../hooks/useScreenedManuscripts";
import { formatReport } from "../../../helpers/reports";

export const AllManuscriptsTab = () => {

  const [currentPage, setCurrentPage] = useState(0);

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);

  /* Old code */
  const [_modalContent, _setModalContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  /* Old code */

  const { data,
    isLoading,
    error,
    pageCount,
    searchTerm,
    setSearchTerm,
    fetchData,
  } = useScreenedManuscripts({ currentPage: currentPage, type: 'all' });

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };


  // ----------------------------------------------------------------------------
  const onViewReport = async (id) => {
    // setIsLoading(true);
    _setModalContent(null);
    try {
      const report = await formatReport(
        data[data.findIndex((item) => item.publisher_manuscript_id === id)]
      );
      _setModalContent(report);
      setIsOpen(true);
    } catch (error) {
      // setError(true);
    } finally {
      // setIsLoading(false);
    }
  };

  const [isOldReportOpen, setIsOldReportOpen] = useState(false);

  // ----------------------------------------------------------------------------------------------------------
  const allManuscriptsWithActions = useMemo(() => {
    return data?.map((manuscript, i) => ({
      ...manuscript,
      timestamp: dayjs(manuscript.timestamp).format("DD MM YYYY"),
      signals: (
        <TooltipButton
          key={i} // Assuming each manuscript has a unique identifier
          manuscript={manuscript}
          onViewReport={onViewReport}
          onViewOldReport={() => setIsOldReportOpen(true)}
          setIsWatchlistModalOpen={setIsWatchlistModalOpen}
        />
      ),
    }));
  }, [data]);

  const handleSearchFetch = async () => {
    if (currentPage === 0) {
      fetchData();
    }
    else {
      setCurrentPage(0)
    }
  }

  return (
    <SpinnerOverlay active={isLoading}>
      {/* <ReportModal
        isReportModalOpen={isOldReportOpen}
        setIsReportModalOpen={setIsOldReportOpen}
      /> */}
      {/*Old report modal */}
      {/* <DuplicateManuscriptModal
        itemId={isDuplicateModalOpen}
        onClose={() => setIsDuplicateModalOpen(false)}
      /> */}
      {/* <WatchlistManuscriptModal
        itemId={isWatchlistModalOpen}
        onClose={() => setIsWatchlistModalOpen(false)}
      /> */}
      {/* OLD CODE */}
      <SimultaneousModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalContent={_modalContent}
        modalType="reportDetails"
      />
      {/* OLD CODE */}
      {error && <ErrorMessage />}
      {!error && data && (
        <>
          <MDBRow className="mb-2 justify-content-end">
            <MDBCol sm={8} md={6} lg={4}>
              <SearchInput
                placeholder="Search by manuscript ID or title"
                value={searchTerm}
                setValue={setSearchTerm}
                onSubmit={handleSearchFetch}
              />
            </MDBCol>
          </MDBRow>
          <DataTable columns={columns} rows={allManuscriptsWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};
