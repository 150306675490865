import React from 'react';
import {
  MDBProgress, MDBProgressBar, MDBRow, MDBCol,
} from 'mdb-react-ui-kit';
import './ProgressBar.css';
import { countProgressColor, countProgressValue } from '../../helpers/helpers';


const ProgressBar = ({ score }) => {
  const progressValue = countProgressValue(score);

  return (
    <MDBRow className="progress_container mx-1">
      <MDBCol size="3" className="progress_column p-0">
      </MDBCol>
      <MDBCol size="3" className="progress_column p-0">
      </MDBCol>
      <MDBCol size="3" className="progress_column p-0">
      </MDBCol>
      <MDBCol size="3" className="progress_column p-0">
      </MDBCol>
      <MDBProgress className="progress_scale">
        <MDBProgressBar bgColor={countProgressColor(progressValue)} width={progressValue} valuemin={0} valuemax={100} />
      </MDBProgress>
    </MDBRow>
  );
};

export default ProgressBar;