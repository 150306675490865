import { createContext, useContext, useEffect } from "react";
import useState from 'react-usestateref';
import { Navigate } from "react-router-dom";
import { token, wstoken } from "../constants";
import { getWsToken, getComponents } from '../helpers/events';
import Spinner from "./Spinner/Spinner";
import { MDBContainer } from "mdb-react-ui-kit";

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem(token));
    const [, setComponents, componentsRef] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchComponents = async () => {
        try {
            if (localStorage.getItem(token)) {
                const res = await getComponents();
                let components = res?.data?.components || {};
                setComponents(components);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchComponents();
    }, []);

    const login = async (auth) => {
        localStorage.setItem(token, auth.data.token);
        localStorage.setItem('username', auth.data.username);

        await fetchComponents();
        let res = await getWsToken();
        localStorage.setItem(wstoken, res.data);
        setAuthenticated(true);
        return componentsRef.current;
    }
    const logout = () => {
        localStorage.removeItem(token);
        localStorage.removeItem(wstoken);
        setAuthenticated(false);
    }
    const isFeatureEnabled = feature => {
        const featureMap = new Map([
            ['OPERATIONAL_SCREENING', 'OPScr'],
            ['PAPERMILL_CHECK', 'PMChk']
        ]);
        return componentsRef.current[featureMap.get(feature)] || false;
    }
    useEffect(() => {
        window.addEventListener('force-logout', logout);
        return () => window.removeEventListener('force-logout', logout);
    }, []);
    if (loading) {
        return <Spinner />;
    }
    return (
        <AuthContext.Provider value={{ login, logout, isAuthenticated, isFeatureEnabled }}>
            {children}
        </AuthContext.Provider>
    )
}
export const RequireAuth = ({ children, redirectTo = "/login", feature }) => {
    const { isAuthenticated, isFeatureEnabled } = useAuth();
    const iAllowed = feature !== undefined ? isFeatureEnabled(feature) : true;
    return (
        isAuthenticated && iAllowed
            ? <MDBContainer className="my-5">
                {children}
            </MDBContainer>
            : <Navigate to={redirectTo} />
    )
}
export default AuthProvider;