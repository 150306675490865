export const token = `${process.env.REACT_APP_TOKEN}`;
export const wstoken = `${process.env.REACT_APP_WSTOKEN}`;
export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
export const webSocketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}`;
export const papermillBaseUrl = `${process.env.REACT_APP_API_PAPERMILL_BASE_URL}`;

export const Region = process.env.REACT_APP_REGION;
export const BucketName = 'publisher-a-inbox';
export const QueueUrl = 'publisher-a-queue.fifo';

export const itemsPerPage = 8;
export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully"

export const notificationsTime = 3000;

export const defaultColor = "#4285F4";
