import { ThreeDots } from "react-loader-spinner";
import styled, { withTheme } from 'styled-components';
import { defaultColor } from "../../constants";

const SpinnerWrapper = styled.div`
  .spinner_container {
    display: flex;
    justify-content: center;
  }

  .spinner_container_small {
    display: flex;
    justify-content: center;
    position: absolute;
  }
}
`

const Spinner = () => (
  <SpinnerWrapper>
    <div className="spinner_container">
      <ThreeDots color={defaultColor} />
    </div>
  </SpinnerWrapper>
);

export default withTheme(Spinner);