import React from 'react';
import {
  MDBTypography,
  MDBCard,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import styled from 'styled-components';


const HrefWrapper = styled.span`
a:link {
  color: black;
  background-color: yellow;
  text-decoration: none;
}

a:hover {
  color: black;
  background-color: yellow;
  text-decoration: underline;
}

a:visited {
  color: black;
  background-color: yellow;
  text-decoration: none;
}
`;

const BottomMargin = styled.div`
  margin-bottom: 15px;
`;

const TorturedPhrases = ({ item, index }) => {
  const parse = require('html-react-parser');


  return (
    <MDBRow className="mt-4">
      {item?.sentence?.map
        ?
        <>
          <MDBCol md="9">
            <MDBCard className="torture_phrase_card px-3 py-4">
              {
                item.sentence.map(row => (
                  <>
                    <HrefWrapper>
                      {parse(row)}
                    </HrefWrapper>
                    <BottomMargin />
                  </>
                ))
              }
            </MDBCard>
          </MDBCol>

          <MDBCol md="3">
            <MDBTypography colorText="grey" variant="h6">
              Indication {index + 1}
            </MDBTypography>
          </MDBCol>
        </>
        :
        <div className='ps-2'>None found</div>
      }
    </MDBRow>
  );
};

export default TorturedPhrases;