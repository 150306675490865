import LoadingOverlay from 'react-loading-overlay'
import Spinner from '../Spinner/Spinner';

const SpinnerOverlay = ({ active, children }) => {
    return (
        <LoadingOverlay
            active={active}
            spinner={<Spinner />}
            fadeSpeed={0}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: `rgba(0, 0, 0, 0)`
                })
            }}
        >
            {children}
        </LoadingOverlay>
    )
}

export default SpinnerOverlay;