import React, { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const popovers = [
  {
    popoverHeader: 'DOI analysis',
    popoverBody:
      <>
        <b>DOI analysis</b> — This signal analyses references in the text.<br />
        &#x25cf; The first number signifies if any references were marked as retracted, received an expression of concern, or were withdrawn in Crossref.<br />
        &#x25cf; The second number signifies the number of DOIs listed in the references list that do not exist (via check with DOI.org).<br />
        &#x25cf; The third number signifies the total number of DOIs that were listed in the reference list or could be retrieved using our software.<br />
        &#x25cf; The number in brackets is the total number of references that were found in the manuscript.
      </>
  },
  {
    popoverHeader: 'PubPeer mentions',
    popoverBody:
      <>
        PubPeer — These signals checks if references received mentions in the PubPeer database, an indication of being problematic (aka Feet-of-Clay tool).<br />
        &#x25cf; The first number displays the number of references that were marked as retracted.<br />
        &#x25cf; The second number displays the number of references that received Comments.<br />
        &#x25cf; The third number signifies the total number of DOIs that were listed in the  reference list or could be retrieved using our software and were sent off to PubPeer.<br />
        &#x25cf; The number in brackets is the total number of references that were found in the manuscript.
      </>
  },
  {
    popoverHeader: 'Papermill similarity',
    popoverBody:
      <>
        <b>Clear Skies.</b><br />
        The papermill alarm alerts when a paper appears similar to past papermill-products. Article metadata is used as input. Results are shown as 'red', 'orange' and 'green'.<br />
        &#x25cf; 'red' - highly similar past papermill-papers found.<br />
        &#x25cf; 'orange' - slightly similar papermill-papers found.<br />
        &#x25cf; 'green' - no similar known past papermill-papers found.<br />
      </>
  },
  {
    popoverHeader: 'Tortured phrases',
    popoverBody:
      <>
        The text of the manuscript is checked against 2,000 phrases that were identified by publishers and Dr. Guillame Cabanac et al. as likely being AI-generated or rewritten. The database is refreshed regularly.
      </>
  },
  {
    popoverHeader: 'Unnatural text',
    popoverBody:
      <>
        <b>GPT-2 Detection</b> — This tool was developed by Guillame Cabanac et al. It returns a percentage that is a measure for the likelihood that a paper is machine-generated or re-written based on the occurrence of tortured phrases in the text.
      </>
  },
  {
    popoverHeader: 'Fake affiliation names',
    popoverBody:
      <>
        Papermill watch list — The STM Integrity Hub maintains a list of fake email domains and institution names that were involved in paper mill activities. A red flag indicates that a match was found.
      </>
  },
  {
    popoverHeader: 'Fake e-mail domains',
    popoverBody:
      <>
        Papermill watch list — The STM Integrity Hub maintains a list of fake email domains and institution names that were involved in paper mill activities. A red flag indicates that a match was found.
      </>
  },
  {
    popoverHeader: 'Disposable e-mail domains',
    popoverBody:
      <>
        Papermill watch list — A list of disposable and temporary email address domains often used to register dummy users in order to spam or abuse some services. See&nbsp;<a href="https://github.com/disposable-email-domains/disposable-email-domains">https://github.com/disposable-email-domains/disposable-email-domains</a>.
      </>
  },
  {
    popoverHeader: 'Papermill watch list',
    popoverBody:
      <>
        Papermill watch list — The STM Integrity Hub maintains a list of fake email domains and institution names that were involved in paper mill activities. A red flag indicates that a match was found.
      </>
  }
];

const SignalInfoPopover = ({ signal, placement = 'right' }) => {
  const ref = useRef(null);
  const { popoverHeader, popoverBody } = popovers.find(element => element.popoverHeader === signal) ?? {};

  return (
    <div ref={ref}>
      <OverlayTrigger
        placement={placement}
        container={ref}
        delay={{ show: 50, hide: 50 }}
        overlay={(
          <Popover id="popover-basic">
            {popoverHeader
              ? <Popover.Header as="h3">
                {popoverHeader}
              </Popover.Header>
              : null}
            {popoverBody
              ? <Popover.Body>
                {popoverBody}
              </Popover.Body>
              : null}
          </Popover>
        )}
      >
        <span><i className="fa fa-info-circle fa-stack-1x info_circle ms-1"></i></span>
      </OverlayTrigger>
    </div>
  )
}

export default SignalInfoPopover;