import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";

const SearchInput = ({ placeholder = '', value, setValue, onSubmit }) => {

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    };

    return (
        <MDBInputGroup className="p-1">
            <input
                className="form-control"
                placeholder={placeholder}
                type="text"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                onKeyDown={handleKeyPress}
            />
            <MDBBtn onClick={onSubmit}>
                Search
            </MDBBtn>
        </MDBInputGroup>
    )
}

export default SearchInput;