export const columns = [
  {
    label: "Date",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal",
  },
  {
    label: "Manuscript id",
    field: "submissionId",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
];

export const testData = [
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
];
