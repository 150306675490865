import React from 'react';
import { MDBBtn, MDBCard, MDBCol, MDBIcon, MDBRow, MDBTypography } from 'mdb-react-ui-kit';
import SignalInfoPopover from '../SignalInfo/SignalInfo';
import DataField from '../DataField/DataField';
import { isEmptyObject } from '../../helpers/helpers';
import dayjs from 'dayjs';
import './ReportCard.css';

const ReportCard = ({ item, index, showInfo }) => {
  const { Attrs, SK } = item;
  const manuscript = (Attrs.documentTitle ? Attrs.documentTitle : Attrs.documentName) ||
    Attrs.externalPublisherId;
  const timestamp = dayjs(SK.slice(10)).format('YYYY.MM.DD HH:mm');

  const viewDetails = (index, tabId) => {
    showInfo(index, tabId);
  }

  const viewDetailsButtonStyle = {
    fontSize: '0.63em',
    fontWeight: '600',
    height: '2rem'
  }

  const createTextReferenceItem = (label, colorCondition, value, isUnavailable = false) => {
    const getTextForPopover = () => {
      if (value === 'n/a') {
        return 'Service not enabled'
      }
      if (isUnavailable) {
        return 'Service unavailable'
      }
      return label;
    }
    return {
      label,
      labelColor: typeof colorCondition !== 'string'
        ? !!colorCondition ? 'red' : null
        : colorCondition,
      value,
      popover: <SignalInfoPopover signal={getTextForPopover()} />,
      isUnavailable: isUnavailable
    };
  }

  const referenceAnalysis = [
    createTextReferenceItem(
      'DOI analysis',
      Attrs?.refIdentifiers?.invalid?.length,
      '' + (Attrs?.refIdentifiers?.invalid?.length ?? 0) +
      '/' + (Attrs?.refIdentifiers?.extracted ?? 0) +
      ' (' + (Attrs?.refIdentifiers?.parsed ?? 0) + ')'
    ),
    createTextReferenceItem(
      'Retraction Watch',
      Attrs?.retractionWatch?.length,
      Attrs?.retractionWatch
        ? '' + (Attrs?.retractionWatch?.length ?? 0) +
        ' (' + (Attrs?.refIdentifiers?.parsed ?? 0) + ')'
        : 'n/a'
    ),
  ];

  const clearSkiesPapermillAlarm = [
    createTextReferenceItem(
      'Papermill similarity',
      !Attrs?.clearSkiesStatus ||
        Attrs?.clearSkiesStatus === 'green' ||
        Attrs?.clearSkiesStatus === 'none' ||
        isEmptyObject(Attrs?.clearSkiesStatus) ? null : 'red',
      Attrs?.clearSkiesStatus === null ? 'n/a' : null,
      !Attrs?.clearSkiesStatus || isEmptyObject(Attrs.clearSkiesStatus)
    ),

  ];

  const text = [
    createTextReferenceItem(
      'Tortured phrases',
      Attrs?.signalsScore,
      Attrs?.signalsScore !== null ? '' + Attrs?.signalsScore : 'n/a',
    ),
    createTextReferenceItem(
      'Unnatural text',
      +(Attrs?.gptDetectorScore ?? 0) > 25,
      Attrs?.gptDetectorScore === null ? 'n/a' : null
    ),
  ]

  const pubPeer = [
    createTextReferenceItem(
      'PubPeer hits',
      Attrs?.feetOfClayRatio?.retracted?.length || Attrs?.feetOfClayRatio?.details?.length,
      Attrs?.feetOfClayRatio
        ? '' + (Attrs?.feetOfClayRatio?.retracted?.length ?? 0) +
        '/' + (Attrs?.feetOfClayRatio?.details?.length ?? 0) +
        '/' + (Attrs?.refIdentifiers?.valid ?? 0) +
        ' (' + (Attrs?.refIdentifiers?.parsed ?? 0) + ')'
        : 'n/a'
    )
  ]

  const createPapermillItem = (label, suspectType) => {
    const suspectData = Attrs?.suspects?.[suspectType];

    return {
      label,
      labelColor: suspectData?.length ? 'red' : null,
      value: Array.isArray(suspectData)
        ? suspectData?.length ? '' + suspectData.length : '0'
        : 'n/a',
      popover: <SignalInfoPopover signal={label} />
    };
  }

  const papermill = [
    createPapermillItem('Fake affiliation names', 'affiliations'),
    createPapermillItem('Fake names or e-mail addresses', 'identities'),
    createPapermillItem('Fake e-mail domains', 'emails'),
    createPapermillItem('Disposable e-mail domains', 'tempmails')
  ];

  return (
    <MDBCard className='report_card_container mb-4'>
      <div className='p-3'>
        <MDBTypography variant='p' className='mb-1'>
          <small>{timestamp}</small>
        </MDBTypography>
        <MDBTypography variant='h5' className='mb-4'>
          {manuscript}
        </MDBTypography>
        <MDBRow>
          <MDBCol>
            <DataField
              title={'Reference analysis'}
              items={referenceAnalysis}
              viewDetails={() => viewDetails(index, 'references')}
            />
          </MDBCol>
          <MDBCol>
            <DataField
              title={'ClearSkies Papermill Alarm'}
              items={clearSkiesPapermillAlarm}
              viewDetails={() => viewDetails(index, 'full_text')}
            />
          </MDBCol>
          <MDBCol>
            <DataField
              title={'Watchlist'}
              items={papermill}
              viewDetails={() => viewDetails(index, 'metadata')}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={4}>
            <DataField
              title={'PubPeer'}
              items={pubPeer}
              viewDetails={() => viewDetails(index, 'references')}
            />
          </MDBCol>
          <MDBCol md={4}>
            <DataField
              title={'Text analysis'}
              items={text}
              viewDetails={() => viewDetails(index, 'full_text')}
            />
          </MDBCol>
        </MDBRow>

        <MDBBtn
          onClick={() => viewDetails(index, 'references')}
          outline
          size='sm'
          color='primary'
          className='mt-3 px-3 d-flex align-items-center'
          style={viewDetailsButtonStyle}
        >
          <MDBIcon icon='eye' size='lg' className='me-2' fixed />
          View Details
        </MDBBtn>
      </div>
    </MDBCard>
  );
};

export default ReportCard;