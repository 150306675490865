export const columns = [
  {
    label: "Recieved",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal_title",
  },
  {
    label: "Manuscript id",
    field: "id",
  },
  {
    label: "Manuscript title",
    field: "title",
    width: 500,
  },
  {
    label: "Status",
    field: "status",
    width: 200
  },
  // {
  //   label: "Action",
  //   field: "action",
  // },
];

export const testData = [
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
    status: "1 duplicate",
    isUnmarkAction: true,
    isConfirmAction: true,
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
    status: "Removed by XXXX",
    isUnmarkAction: true,
    isConfirmAction: false,
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
    status: "Unmarked by XXXX",
    isUnmarkAction: true,
    isConfirmAction: false,
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
    status: "Confirmed duplicate",
    isUnmarkAction: false,
    isConfirmAction: false,
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
    status: "Confirmed paper mill",
    isUnmarkAction: false,
    isConfirmAction: false,
  },
];
