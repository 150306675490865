import React from 'react';
import { MDBCard } from "mdb-react-ui-kit";
import RefLink from '../RefLink/RefLink';
import SignalInfoPopover from '../SignalInfo/SignalInfo';

const ReferencesTab = ({ crossref, refIdentifiers, referencesRetractions }) => {
  const { eoc, retracted, withdrawn } = crossref || {};
  const { extracted, invalid, parsed, valid } = refIdentifiers || {};
  const { details: mentions, retracted: retractedRefs } = referencesRetractions || {};

  return (
    <>
      <MDBCard className={'mt-4 bg-opacity-25 ' +
        (!(eoc?.length || retracted?.length || withdrawn?.length) ? 'bg-success' : 'bg-danger')} >

        <div className={'card-header p-3 pt-4 pb-3 border-0'}>
          <div className="d-flex justify-content-between">
            <h5 className="card-title fw-normal">
              DOI analysis
            </h5>
            <div className="d-flex align-items-center text-primary">
              <SignalInfoPopover signal={'DOI analysis'} placement='left' />
            </div>
          </div>
        </div>
        <div className={`card-body bg-white bg-opacity-25 p-3`}>
          <div className="mb-3">
            <p className="fw-bold mb-1">
              References marked as 'expression of concern':
            </p>
            {
              eoc?.length
                ? (eoc.map((item, index) => (
                  <RefLink item={item} index={index} key={item} />
                )))
                : crossref
                  ? 'None found'
                  : 'N/A'
            }
          </div>
          <div className="mt-3 mb-1">
            <p className="fw-bold mb-1">
              References marked as 'retracted':
            </p>
            {
              retracted?.length
                ? (retracted.map((item, index) => (
                  <RefLink item={item} index={index} key={item} />
                )))
                : crossref
                  ? 'None found'
                  : 'N/A'
            }
          </div>
          <div className="mt-3 mb-1">
            <p className="fw-bold mb-1">
              References marked as 'withdrawn':
            </p>
            {
              withdrawn?.length
                ? (withdrawn.map((item, index) => (
                  <RefLink item={item} index={index} key={item} />
                )))
                : crossref
                  ? 'None found'
                  : 'N/A'
            }
          </div>
        </div>
        <div className={'card-footer border-0 px-3 py-4'}>
          <div>
            <span className="fw-normal">
              Number of listed DOIs that do not exist:
            </span>
            <span className="ms-1">
              {invalid?.length && invalid.length > 0 ? invalid.length : (invalid?.length ?? '-')}
            </span>
          </div>
          <div>
            <span className="fw-normal">
              Total number of references listed in manuscript: {(parsed ?? '-')}
              <br />
              From which DOIs were listed or could be retrieved: {(extracted ?? '-')}
            </span>
          </div>
        </div>
      </MDBCard>

      <MDBCard className={'mt-4 bg-opacity-25 ' +
        (!(mentions?.length || retractedRefs?.length) ? 'bg-success' : 'bg-danger')} >
        <div className={'card-header p-3 pt-4 pb-3 border-0'}>
          <div className="d-flex justify-content-between">
            <h5 className="card-title fw-normal">
              PubPeer mentions
            </h5>
            <div className="d-flex align-items-center text-primary">
              <SignalInfoPopover signal={'PubPeer mentions'} placement='left' />
            </div>
          </div>
        </div>
        <div className={`card-body p-3 bg-white bg-opacity-25`}>
          <div className="mb-1">
            <p className="mb-1 fw-bold">
              References retracted:
            </p>
            {
              retractedRefs?.length
                ? (
                  retractedRefs.map((item, index) => (
                    <RefLink item={item} pubpeer={true} index={index} key={index} textColor={'white'} />
                  )))
                : referencesRetractions
                  ? 'None found'
                  : 'N/A'
            }
          </div>
          <div className="mt-3 mb-1">
            <p className="mb-1 fw-bold">
              Mentions:
            </p>
            {
              mentions?.length
                ? (
                  mentions.map(({ doi, comments }, index) => (
                    <RefLink item={doi} pubpeer={true} index={index} key={index} comments={comments} textColor={'white'} />
                  )))
                : referencesRetractions
                  ? 'None found'
                  : 'N/A'
            }
          </div>
        </div>
        <div className={'card-footer border-0 px-3 py-4'}>
          <div>
            <span className="fw-normal">
              Total number of references listed in manuscript: {(parsed ?? '-')}
              <br />
              From which DOIs were listed or could be retrieved: {(extracted ?? '-')}
            </span>
          </div>

        </div>

      </MDBCard>
    </>
  );
};


export default ReferencesTab;