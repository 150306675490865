import React from 'react';
import { MDBTypography } from "mdb-react-ui-kit";


const ModalHeader = ({ title, onDeleteEvent, onLoadReport, eventDeleting }) => {

  return (
    <>
      <MDBTypography colorText="blue" className="ms-1 fw-normal" variant="h4">
        {title}
      </MDBTypography>

      <div className="d-flex mt-4">
        <button
          type="button"
          className="btn btn-tertiary text-dark"
          disabled={eventDeleting}
          onClick={onLoadReport}
        >
          <i className="pe-2 fa fa-cloud-download-alt fa-lg"></i>
          Download report
        </button>
        <button
          type="button"
          className="btn btn-tertiary text-danger ms-3"
          disabled={eventDeleting}
          onClick={onDeleteEvent}
        >
          <i className="pe-2 fa fa-trash fa-md"></i>
          {eventDeleting ? "Removing file" : "Remove file"}
        </button>
      </div>
    </>
  );
};


export default ModalHeader;

