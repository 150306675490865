import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';
import NoContentMessage from '../../components/NoContentMessage/NoContentMessage';
import DataTable from '../../components/table/DataTable';
import { findReports } from '../../helpers/reports';
import Spinner from '../../components/Spinner/Spinner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Jsontableify from 'jsontableify';
import { useParams } from 'react-router-dom';
import './Reports.css';
import Modal from '../../components/Modal/Modal';

const Reports = () => {
  const { id } = useParams();
  const [dataBase, setDataBase] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      label: 'ID',
      field: 'id',
      width: 100,
    },
    {
      label: 'SK',
      field: 'sk',
      width: 100,
    },
    {
      label: 'Actions',
      field: 'actions',
      width: 50,
    }
  ];

  useEffect(() => {
    setLoading(true);

    findReports(id)
      .then((res) => {
        if (res.data.length) {
          setDataBase(res.data);
          setLoading(false);
        } else {
          throw new Error(`No reports found for the specified id: ${id}`)
        }
      })
      .catch((err) => {
        console.error('ERR:', err);
        setLoading(false);
        setError(false);
      });
  }, [id]);

  const formTableRows = data => {
    const rows = data.map(item => {
      return {
        id: item.GSI3SK,
        sk: item.SK,
        actions:
          <MDBBtnGroup>
            <MDBBtn size="sm" color="primary" className="my-0" onClick={() => onViewReport(item.SK)}>
              View
            </MDBBtn>
          </MDBBtnGroup>
      }
    });

    return rows;
  };

  const onViewReport = sk => {
    const jt = new Jsontableify();
    const report = dataBase.find(report => report.SK === sk);
    const html = jt.toHtml(report).html;
    setModalContent(html);
    setIsOpen(true);
  };

  return (
    <div className='container'>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalContent={modalContent}
        modalType="reportDetails"
      />
      <div className='my-5'>
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorMessage />
        ) : (
          <>
            {dataBase ? (
              <>
                <DataTable
                  columns={columns}
                  rows={formTableRows(dataBase)}
                  tableHeaderTitle={'Reports '}
                />
              </>
            ) : (
              <NoContentMessage message={'No data'} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Reports;
