import { MDBContainer } from "mdb-react-ui-kit";
import { CountCard } from "../../components";
import DataTable from "../../components/table/DataTable";
import { useState } from "react";
import { ReferenceAnalysisReviewModal } from "./components/ReferenceAnalysisModal";
import { columns, testData } from "./columns";
import dayjs from "dayjs";

const ReferenceAnalysis = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dataWithActions = testData.map((element) => ({
    ...element,
    timestamp: dayjs(element.timestamp).format("DD MM YYYY"),
    clickEvent: () => setIsModalOpen(true),
  }));

  return (
    <MDBContainer className="my-5">
      <ReferenceAnalysisReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div className="d-flex justify-content-center m-5">
        <CountCard count={10} title={"Matches"} subtitle={"Current matches"} />
      </div>
      <DataTable columns={columns} rows={dataWithActions} />
    </MDBContainer>
  );
};

export default ReferenceAnalysis;
