import "./NoContentMessage.css";


const NoContentMessage = ({ message }) => (
  <div className="no_content_container">
    <h5 className="no_content_message">
      {message}
    </h5>
  </div>
);

export default NoContentMessage;