import dayjs from "dayjs";
import { axiosInstance } from "./axiosSetup";
import { getEventByPersistentId } from "./events";
import { handleErrors } from "./handleErrors";

export async function getReportsScreenedManuscripts(
    startrow,
    maxrows,
    type,
    filter
) {
    return axiosInstance
        .post(`hub-api/proxy`, {
            queryStringParameters: {
                maxrows: maxrows,
                startrow: startrow,
                filter: filter,
                type: type,
            },
            path: "/sharereports",
            method: "get",
        })
}

export async function getReportsScreenedManuscriptsCount() {
    return axiosInstance.post(`hub-api/proxy`, {
        path: "/reports/count",
        method: "get",
    });
}

export async function getReportsDuplicateSubmission(startrow, maxrows, type) {
    return axiosInstance.post(`hub-api/proxy`, {
        queryStringParameters: {
            maxrows: maxrows,
            startrow: startrow,
            type: "all",
        },
        path: "/reports/dup-sub",
        method: "get",
    });
}

export async function getReportsDuplicateSubmissionCount() {
    return axiosInstance.post(`hub-api/proxy`, {
        path: "/reports/dup-sub/count",
        method: "get",
    });
}

export async function findReports(id) {
    return axiosInstance
        .get(`find_reports/${id}`)
        .catch(handleErrors)
};

export async function getReports(offset, perPage, mode, filter = '') {
    return { data: defaultReports };
    // return axiosInstance
    // .get(`get_reports?startrow=${offset}&maxrows=${perPage}&partition=${mode}&filter=${encodeURIComponent(filter)}`)
    // .catch(handleErrors)
};

export async function deleteReport(id, indexErrors = false) {
    return axiosInstance
        .delete(`remove_report/${id}?index=${indexErrors}`)
};


const formatReportItem = async (item, isMatch = false) => {
    const dateKey = isMatch ? "submitted" : "timestamp";
    const manuscriptIdKey = isMatch
        ? "manuscript_id"
        : "publisher_manuscript_id";
    const journalTitleKey = isMatch ? "journal" : "journal_title";
    const {
        [dateKey]: date,
        [manuscriptIdKey]: manuscript_id,
        [journalTitleKey]: journal_title,
        publisher_name,
        title,
        persistent_id,
    } = item;

    let feedback_email = "";
    if (isMatch) {
        const eventData = await getEventByPersistentId(persistent_id);
        feedback_email = eventData.data?.message?.feedback_email;
        // feedback_email = eventData?.feedback_email;
    // }
    // else {
        // console.log('ITEM', item)
        // feedback_email = item.feedback_email
    }

    return {
        Date: dayjs(date).format("YYYY/MM/DD HH:mm:ss"),
        Publisher:
            isMatch && feedback_email
            // feedback_email
                ? `<a href="mailto:${feedback_email}?subject=${manuscript_id}">${publisher_name}</a>`
                : item.publisher_name,
        Journal: journal_title,
        ManuscriptID: manuscript_id,
        Title: title,

    };
};

export const formatReport = async (data) => {
    const {
        timestamp,
        title,
        journal_title,
        matches,
        total,
        publisher_name,
        publisher_manuscript_id,
        // feedback_email
    } = data;

    const formattedMatches = await Promise.all(
        matches?.map(async (match) => await formatReportItem(match, true))
    );

    const formattedReport = await formatReportItem({
        timestamp,
        title,
        journal_title,
        publisher_name,
        publisher_manuscript_id,
        // feedback_email
    });

    return {
        ...formattedReport,
        Matches: total,
        "Matched manuscripts": formattedMatches.length ? formattedMatches : null,
    };
};

const defaultReports = {
    "events": [
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        },
        {
            "id": 53309,
            "timestamp": "2024-03-25T23:53:40.642Z",
            "publisher_id": 18,
            "publisher_name": "Journal A",
            "feedback_email": "editor@journala.com",
            "journal_title": "Journal A",
            "persistent_id": "Journal-A-98716",
            "publisher_manuscript_id": "98716",
            "manuscript_title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "total": 1,
            "matches": [
                {
                    "score": 59.694183,
                    "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
                    "journal": "Frontiers in Public Health",
                    "submitted": "2024-03-25T23:47:24.165Z",
                    "publisher_id": 15,
                    "manuscript_id": "1406984",
                    "persistent_id": "frontiersin.org-1406984",
                    "feedback_email": "research.integrity@frontiersin.org",
                    "publisher_name": "Frontiers",
                    "publisher_manuscript_id": "1406984"
                }
            ],
            "title": "The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters",
            "score": 59.694183
        },
        {
            "id": 52675,
            "timestamp": "2024-03-25T06:15:21.529Z",
            "publisher_id": 18,
            "publisher_name": "Journal B",
            "feedback_email": "editor@journalb.com",
            "journal_title": "Journal B",
            "persistent_id": "Journal-B-98679",
            "publisher_manuscript_id": "98679",
            "manuscript_title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "total": 1,
            "matches": [
                {
                    "score": 48.72459,
                    "title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
                    "journal": "IEEE Access",
                    "submitted": "2024-03-04T08:44:40.979Z",
                    "publisher_id": 16,
                    "manuscript_id": "Access-2024-08650",
                    "persistent_id": "ieee-07YkS-53846340",
                    "feedback_email": "similaritycheck@ieee.org",
                    "publisher_name": "IEEE",
                    "publisher_manuscript_id": "Access-2024-08650"
                }
            ],
            "title": "Transformer meets external context: A novel approach to enhance neural machine translation",
            "score": 48.72459
        },
        {
            "id": 44675,
            "timestamp": "2024-03-13T10:52:13.250Z",
            "publisher_id": 18,
            "publisher_name": "Journal C",
            "feedback_email": "editor@journalc.com",
            "journal_title": "Journal C",
            "persistent_id": "journalc-nITOP-98027",
            "publisher_manuscript_id": "98027",
            "manuscript_title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "total": 1,
            "matches": [
                {
                    "score": 51.48508,
                    "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning.",
                    "journal": "Applied Intelligence",
                    "submitted": "2024-03-08T10:48:41.912Z",
                    "publisher_id": 20,
                    "manuscript_id": "apin56587",
                    "persistent_id": "sn-XhYpp-56587",
                    "feedback_email": "john.bell@springernature.com",
                    "publisher_name": "SpringerNature",
                    "publisher_manuscript_id": "apin56587"
                }
            ],
            "title": "Enhancing Breast Cancer Detection: Unveiling the Power of Thermal Imaging and Modern Deep Learning Techniques with Transfer Learning",
            "score": 51.48508
        }
    ],
    "total": 45
}