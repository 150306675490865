import { itemsPerPage as itemsPerPageDefault } from '../../../constants';
import { useState, useEffect } from 'react';
import { getReportsDuplicateSubmission } from '../../../helpers/reports';
import dayjs from 'dayjs';
import { showErrorToast } from '../../../helpers/helpers';

const useDuplicateSubmissions = ({ currentPage = 0, doInitialFetch = true, itemsPerPage = itemsPerPageDefault, type }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const fetchData = async () => {
        setIsLoading(true);
        setError(false);
        try {
            const { data } = await getReportsDuplicateSubmission(
                currentPage * itemsPerPage + 1,
                itemsPerPage,
                type,
            );

            const formattedData = data.events.map((x) => {
                return {
                    ...x,
                    matches: [],
                    total: 0,
                    timestamp: dayjs(x.timestamp).format("DD MM YYYY HH:mm"),
                };
            });
            setData(formattedData);
            setPageCount(Math.ceil(data.total / itemsPerPage));
        } catch (err) {
            showErrorToast();
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (doInitialFetch) {
            fetchData();
        }
    }, [currentPage]);

    return {
        data, isLoading, error, pageCount, fetchData
    };
};

export default useDuplicateSubmissions;
