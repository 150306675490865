export const columns = [
    {
        label: 'Received',
        field: 'timestamp',
        width: 120,
    },
    {
        label: 'Journal',
        field: 'journal_title',
        width: 120,
    },
    {
        label: 'Manuscript id',
        field: 'id',
        width: 150,
    },
    {
        label: 'Manuscript title',
        field: 'title',
    },
    {
        label: 'Signals',
        field: 'signals'
    },

    // possible signals:
    // 'duplicate-submission'
    // 'watchlist'
    // 'suspicious-reference'
    // 'on-demand-screening'

    {
        items: [
            {
                id: 1,
                timestamp: '2024-03-25T23:53:40.642Z',
                journal_title: 'Journal A',
                title: 'The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters',
                signals: ['on-demand-screening', 'watchlist']
            },
            {
                id: 1,
                timestamp: '2024-03-25T23:53:40.642Z',
                journal_title: 'Journal A',
                title: 'The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters',
                signals: ['watchlist']
            },
            {
                id: 2,
                timestamp: '2024-03-25T23:53:40.642Z',
                journal_title: 'Journal A',
                title: 'The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters',
                signals: ['suspicious-reference']
            },
            {
                id: 3,
                timestamp: '2024-03-25T23:53:40.642Z',
                journal_title: 'Journal A',
                title: 'The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters',
                signals: ['duplicate-submission', 'on-demand-screening', 'suspicious-reference']
            },
            {
                id: 4,
                timestamp: '2024-03-25T23:53:40.642Z',
                journal_title: 'Journal A',
                title: 'The Impact of Strobe Visual Training on the Sports Vision Abilities of Elite Clay Target Shooters',
                signals: []
            }
        ],
        total: 4
    },







    // DUPLICATE SUBMISSIONS:

    {
        items: [
            {
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
                status: "1 duplicate",
                isUnmarkAction: true,
                isConfirmAction: true,
            },
            {
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
                status: "Removed by XXXX",
                isUnmarkAction: true,
                isConfirmAction: false,
            },
            {
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
                status: "Unmarked by XXXX",
                isUnmarkAction: true,
                isConfirmAction: false,
            },
            {
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
                status: "Confirmed duplicate",
                isUnmarkAction: false,
                isConfirmAction: false,
            },
            {
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
                status: "Confirmed paper mill",
                isUnmarkAction: false,
                isConfirmAction: false,
            },
        ],
        total: 4
    },


    // WATCHLIST
    {
        items: [
            {
                id: 1,
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
            },
            {
                id: 2,
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
            },
            {
                id: 3,
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
            },
            {
                id: 4,
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
            },
            {
                id: 5,
                timestamp: "2024-03-25T23:53:40.642Z",
                journal: "Journal A",
                submissionId: "MS1234",
                title: "Sample Manuscript 1",
            },
        ],
        total: 5
    },
]