import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import { useState } from "react";
import SimultaneousModal from "../../../components/SimultaneousModal/SimultaneousModal";
import { UnmarkActionModal } from "../components/UnmarkActionModal";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import Pagination from "../../../components/Pagination/Pagination";
import { formatReport } from "../../../helpers/reports";

const MatchedManuscriptsTab = () => {
  const [currentPage, setCurrentPage] = useState(0);

  /* Old code */
  const [_modalContent, _setModalContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  /* Old code */

  const [isUnmarkModalOpen, setIsUnmarkModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { data,
    isLoading,
    error,
    pageCount
  } = useDuplicateSubmissions({ currentPage, type: 'matched' });


  const onViewReport = async (id) => {
    // setIsLoading(true);
    _setModalContent(null);
    try {
      const report = await formatReport(data[data.findIndex((item) => item.publisher_manuscript_id === id)]);
      _setModalContent(report);
      setIsOpen(true);
    } catch (error) {
      // setError(true);
    } finally {
      // setIsLoading(false);
    }
  };


  const dataWithActions = data.map((element) => ({
    ...element,
    clickEvent: async () => await onViewReport(element?.publisher_manuscript_id),
    action: (
      <div className="d-flex">
        {element.isUnmarkAction && (
          <MDBTooltip tag="div" title="Unmark" placement="top">
            <MDBBtn
              color="secondary"
              floating
              onClick={(event) => {
                event.stopPropagation();
                setIsUnmarkModalOpen(true);
              }}
              className="mx-2"
            >
              <MDBIcon fas icon="xmark" />
            </MDBBtn>
          </MDBTooltip>
        )}
        {element.isConfirmAction && (
          <MDBTooltip tag="div" title="Confirm" placement="top">
            <MDBBtn
              color="secondary"
              floating
              onClick={(event) => {
                event.stopPropagation();
                setIsConfirmModalOpen(true);
              }}
              className="mx-2"
            >
              <MDBIcon fas icon="check" />
            </MDBBtn>
          </MDBTooltip>
        )}
      </div>
    ),
  }));

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <SpinnerOverlay active={isLoading}>
      {/* OLD CODE */}
      <SimultaneousModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalContent={_modalContent}
        modalType="reportDetails"
      />
      {/* <UnmarkActionModal
        itemId={isUnmarkModalOpen}
        onSuccessSubmit={() => { }}
        onClose={() => setIsUnmarkModalOpen(false)}
      />
      <ConfirmActionModal
        itemId={isConfirmModalOpen}
        onSuccessSubmit={() => { }}
        onClose={() => setIsConfirmModalOpen(false)}
      /> */}
      {/* OLD CODE */}
      {error && <ErrorMessage />}
      {!error && data && (
        <>
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>)}
    </SpinnerOverlay>
  );
};

export default MatchedManuscriptsTab;
