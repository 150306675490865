import React from 'react';
import { withFormik } from 'formik';
import './Login.css';
import LoginForm from '../../components/LoginForm';
import { authorize } from '../../helpers/events';
import { showErrorToast } from '../../helpers/helpers';

const Login = (props) => {
    return (
        <div className='container form_wrapper d-flex justify-content-center'>
            <LoginForm {...props} />
        </div>
    )
};

const mapPropsToValues = () => ({
    username: '',
    password: '',
});

const handleSubmit = async (value, { props }) => {
    try {
        const auth = await authorize({
            ...value,
            username: value.username.trim().toLowerCase()
        });
        await props.login(auth);
        if (props.isFeatureEnabled('OPERATIONAL_SCREENING')) {
            props.navigate('/screened-manuscripts');
        } else if (props.isFeatureEnabled('PAPERMILL_CHECK')) {
            props.navigate('/papermill-screening');
        } else {
            showErrorToast('No permitted services');
        }
    } catch (error) {
        if (error.response?.status === 401) {
            showErrorToast('Not authorized');
        } else {
            showErrorToast('Error, something went wrong');
        }
    }
};

export default withFormik({ handleSubmit, mapPropsToValues })(Login);
