import React from 'react';
import { MDBCard, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TorturedPhrases from '../TorturedPhrases/TorturedPhrases';
import ScoreCard from '../ScoreCard/ScoreCard';
import SignalInfoPopover from '../SignalInfo/SignalInfo';

const FullTextTab = ({ text, tortured_phrases, aiScore }) => {
  const mappedSimilarityColors = new Map([
    ['n/a', 'bg-success'],
    ['none', 'bg-success'],
    ['green', 'bg-success'],
    ['orange', 'bg-warning'],
    ['red', 'bg-danger'],
  ]);

  return (
    <>
      <MDBCard className={`mt-4 bg-opacity-25 ${mappedSimilarityColors.get(text.toLowerCase())}`}>
        <div className={'card-header p-3 pt-4 pb-3 border-0'}>
          <div className='d-flex justify-content-between'>
            <h5 className='card-title fw-normal'>
              Papermill similarity
            </h5>
            <div className='d-flex align-items-center text-primary'>
              <SignalInfoPopover signal={'Papermill similarity'} placement='left' />
            </div>
          </div>
        </div>
        <div className={`card-body bg-white bg-opacity-25 p-3`}>
          <span>{text}</span>
        </div>
      </MDBCard>

      <MDBCard className={`mt-4 bg-opacity-25 ${(!tortured_phrases?.length ? 'bg-success' : 'bg-white')}`}>
        <div className={`card-header p-3 pt-4 border-0 bg-opacity-25 ${(tortured_phrases?.length ? 'bg-danger' : null)}`}>
          <div className='mb-2 d-flex justify-content-between'>
            <h5 className='card-title fw-normal'>Tortured phrases</h5>
            <div className='d-flex align-items-center text-primary'>
              <SignalInfoPopover signal={'Tortured phrases'} placement='left' />
            </div>
          </div>
          <span>
            Strange terms often a result of automatic translation or attempts to disguise plagiarism. An exact or approximate match (fuzzy) of our list of tortured phrases will trigger a score, which increases as more are found
          </span>
          <div className="d-flex mt-2">
            <span className="fw-bold">
              Database:
              <span className="ms-1">
              </span>
              3613 phrases
            </span>
          </div>
        </div>
        <div className={`card-body bg-white bg-opacity-25 ${tortured_phrases?.length ? 'mb-4' : ''}`}>
          {
            tortured_phrases?.length
              ? tortured_phrases.map((item, index) => (
                <TorturedPhrases
                  key={item.sentence}
                  item={item}
                  index={index}
                />
              ))
              : tortured_phrases === null ? 'N/A' : 'None found'
          }
        </div>
      </MDBCard>

      <MDBCard className={'px-3 py-4 mt-4 text-dark bg-opacity-25 ' +
        (aiScore === 'N/A' || aiScore <= 25 ? 'bg-success' : 'bg-danger')} >
        <MDBRow>
          <MDBCol md="9">
            <div className="d-flex justify-content-between">
              <h5 className="fw-normal">
                Unnatural text
              </h5>
              <div className="d-flex align-items-center text-primary">
                <SignalInfoPopover signal={'Unnatural text'} placement='left' />
              </div>
            </div>
            <span className="mb-4">
              We map all text using an algorithm which puts indications on a scale. Using a corpus of generated Al articles we evaluate all indications to each other to find evidence for Al generation.
            </span>
          </MDBCol>

          <MDBCol md="3">
            <ScoreCard score={aiScore} />
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </>
  );
};


export default FullTextTab;