import { ModalWrapper } from "../../../components/ModalWrapper"

export const ReferenceAnalysisReviewModal = ({ onClose, isOpen }) => {
    return (
        <ModalWrapper
            title={'Reference analysis review'}
            isOpen={isOpen}
            isLoading={false}
            onClose={onClose}
        >
        </ModalWrapper>
    )
}