import { MDBContainer } from "mdb-react-ui-kit";
import { CountCard } from "../../components";
import DataTable from "../../components/table/DataTable";
import { useState } from "react";
import { DetailedViewModal } from "./components/WatchlistReviewModal";
import dayjs from "dayjs";

const columns = [
  {
    label: "Date",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal",
  },
  {
    label: "Manuscript id",
    field: "submissionId",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
];

const data = [
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
  {
    timestamp: "2024-03-25T23:53:40.642Z",
    journal: "Journal A",
    submissionId: "MS1234",
    title: "Sample Manuscript 1",
  },
];

const Watchlist = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dataWithActions = data.map((element) => ({
    ...element,
    timestamp: dayjs(element.timestamp).format("DD MM YYYY"),
    clickEvent: () => setIsModalOpen(true),
  }));

  return (
    <MDBContainer className="my-5">
      <DetailedViewModal
        item2={isModalOpen}
        // isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div className="d-flex justify-content-center m-5">
        <CountCard count={10} title={"Matches"} subtitle={"Current matches"} />
      </div>
      <DataTable columns={columns} rows={dataWithActions} />
    </MDBContainer>
  );
};

export default Watchlist;
