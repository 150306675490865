import React from 'react';
import { MDBCard } from "mdb-react-ui-kit";
import ProgressBar from '../ProgressBar/ProgressBar';

const ScoreCard = ({ score }) => {

  return (
    <MDBCard className="torture_phrase_card py-3">
      <ProgressBar score={score} />
      <div className="m-2">
        <span className="fw-bold">
          Score: {score}
        </span>
      </div>
    </MDBCard>
  );
};

export default ScoreCard;