import React from 'react'
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { AuthProvider, RequireAuth, useAuth } from "./components/Auth";
import { ContextWrapper } from "./components/AppContext";
import Logo from './components/Logo';
import Navbar from './components/Navbar';
import Login from './pages/login/Login';
import Reports from './pages/reports/Reports';
import ScreenedManuscripts from './pages/screened-manuscripts/ScreenedManuscripts';
import DuplicateSubmissions from './pages/duplicate-submissions/DuplicateSubmissions';
import Watchlist from './pages/watchlist/Watchlist';
import ReferenceAnalysis from './pages/reference-analysis/ReferenceAnalysis';
import FullTextScreening from './pages/full-text-screening/FullTextScreening';

const AuthLayout = () => {
    return (
        <>
            <Logo />
            <Outlet />
        </>
    )
}

const MainLayout = () => {
    return (
        <>
            <Logo />
            <Navbar />
            <Outlet />
        </>
    )
}

const EntryPoint = () => {
    const navigate = useNavigate();
    const { login, isFeatureEnabled } = useAuth();

    return (
        <Login navigate={navigate} login={login} isFeatureEnabled={isFeatureEnabled} />
    )
}

const Layout = () => {
    return (
        <ContextWrapper>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<AuthLayout />} >
                            <Route path='*' element={<EntryPoint />} />
                            <Route path='/login' element={<EntryPoint />} />
                            <Route path='/reports/:id' element={<Reports />} />
                        </Route>
                        <Route element={<MainLayout />}>
                            <Route path='/screened-manuscripts' element={
                                <RequireAuth>
                                    <ScreenedManuscripts />
                                 </RequireAuth>
                            } />
                            <Route path='/duplicate-submissions' element={
                                <RequireAuth>
                                    <DuplicateSubmissions />
                                </RequireAuth>
                            } />
                            <Route path='/watchlist' element={
                                // <RequireAuth>
                                <Watchlist />
                                // </RequireAuth>
                            } />
                            <Route path='/reference-analysis' element={
                                // <RequireAuth>
                                <ReferenceAnalysis />
                                // </RequireAuth>
                            } />
                            <Route path='/full-text-screening' element={
                                // <RequireAuth>
                                <FullTextScreening />
                                // </RequireAuth>
                            } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ContextWrapper>
    )
}

export default Layout;
