import React from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBTypography,
} from "mdb-react-ui-kit";

const DataTable = ({
  columns,
  rows,
  areRowsPreMapped = false,
  title = null,
}) => {
  return (
    <>
      {title && (
        <MDBTypography tag={"h3"} className="mb-5 text-center text-muted">
          {title}
        </MDBTypography>
      )}
      <MDBTable responsive className="px-2" style={{ cursor: "pointer" }} hover>
        <MDBTableHead light>
          <tr>
            {columns.map((column, idx) => (
              <th key={idx} style={{ width: column.width }}>{column.label}</th>
            ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {areRowsPreMapped
            ? rows
            : rows.map((row, idx) => (
              <tr key={idx} onClick={row?.clickEvent} className="row-hover">
                {columns.map((column, idx) => (
                  <td key={idx} style={{ width: column.width }}>
                    {column.format
                      ? column.format(row[column.field])
                      : row[column.field]}
                  </td>
                ))}
              </tr>
            ))}
        </MDBTableBody>
      </MDBTable>
    </>
  );
};

export default DataTable;
