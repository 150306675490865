import React from 'react';
import { MDBCard } from "mdb-react-ui-kit";
import SignalInfoPopover from '../SignalInfo/SignalInfo';


const MetadataTab = ({ suspects }) => {
  const { affiliations, emails, tempmails } = suspects || {};

  return (
    <>
      <MDBCard className={'mt-4 bg-opacity-25 bg-opacity-25 ' +
        (!(affiliations?.length || emails?.length || tempmails?.length) ? 'bg-success' : 'bg-danger')} >

        <div className={'card-header p-3 pt-4 pb-3 border-0'}>
          <div className='d-flex justify-content-between'>
            <h5 className='fw-normal'>
              Papermill watch list
            </h5>

            <div className="d-flex align-items-center text-primary">
              <SignalInfoPopover signal={'Papermill watch list'} placement='left' />
            </div>
          </div>
        </div>

        <div className={`card-body bg-white bg-opacity-25 p-3`}>
          <div className="mb-3">
            <p className="fw-bold mb-1">
              Fake affiliation names:
            </p>
            {
              affiliations?.length
                ? (affiliations.map((item, index) => (
                  <p key={item} className="mb-1">{index + 1}. {item}</p>
                )))
                : Array.isArray(affiliations)
                  ? 'None found'
                  : 'N/A'
            }
          </div>
          <div className="mb-3">
            <p className="fw-bold mb-1">
              Fake e-mail domains:
            </p>
            {
              emails?.length
                ? (emails?.map((item, index) => (
                  <p key={item} className="mb-1">{index + 1}. {item}</p>
                )))
                : Array.isArray(emails)
                  ? 'None found'
                  : 'N/A'
            }
          </div>
          <div className="mb-0">
            <p className="fw-bold mb-1">
              Disposable e-mail domains:
            </p>
            {
              tempmails?.length
                ? (tempmails.map((item, index) => (
                  <p key={item} className="mb-1">{index + 1}. {item}</p>
                )))
                : Array.isArray(tempmails)
                  ? 'None found'
                  : 'N/A'
            }
          </div>
        </div>

      </MDBCard>
    </>
  );
};


export default MetadataTab;