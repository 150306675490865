import React, { useState, useEffect } from "react";
import {
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
  MDBContainer,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { showErrorToast } from "../../helpers/helpers";
import { getReportsScreenedManuscriptsCount } from "../../helpers/reports";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import { CountCard } from "../../components";
import { UnderReviewManuscriptsTab } from "./tabs/UnderReviewManuscriptsTab";
import { AllManuscriptsTab } from "./tabs/AllManuscriptsTab";

const tabs = {
  under_review_manuscripts: "under_review_manuscripts",
  all_manuscripts: "all_manuscripts",
};

const ScreenedManuscripts = () => {
  const [currentTab, setCurrentTab] = useState(tabs.under_review_manuscripts);
  const [isLoading, setIsLoading] = useState(false);

  const [manuscriptsData, setManuscriptsData] = useState(null);

  useEffect(() => {
    const fetchManuscripts = async () => {
      try {
        setIsLoading(true);
        const { data } = await getReportsScreenedManuscriptsCount();
        setManuscriptsData(data);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchManuscripts();
  }, []);

  return (
    <MDBContainer>
      <SpinnerOverlay active={isLoading}>
        <div className="d-flex justify-content-center m-5">
          <CountCard
            count={manuscriptsData?.underReviewManuscriptsCount ?? 0}
            title="Manuscripts"
            subtitle="under review"
            onClick={() => setCurrentTab(tabs.under_review_manuscripts)}
          />
          <CountCard
            count={manuscriptsData?.underReviewManuscriptsCount ?? 0}
            title={"Manuscripts"}
            subtitle={"all screened"}
            onClick={() => setCurrentTab(tabs.all_manuscripts)}
          />
        </div>
      </SpinnerOverlay>
      <MDBContainer>
        <MDBTabs className="mb-3">
          <MDBTabsItem
            onClick={() => setCurrentTab(tabs.under_review_manuscripts)}
          >
            <MDBTabsLink active={currentTab === tabs.under_review_manuscripts}>
              Under Review
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem onClick={() => setCurrentTab(tabs.all_manuscripts)}>
            <MDBTabsLink active={currentTab === tabs.all_manuscripts}>
              All Manuscripts
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane show={currentTab === tabs.under_review_manuscripts}>
            <UnderReviewManuscriptsTab />
          </MDBTabsPane>
          <MDBTabsPane show={currentTab === tabs.all_manuscripts}>
            <AllManuscriptsTab />
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBContainer>
    </MDBContainer>
  );
};

export default ScreenedManuscripts;
