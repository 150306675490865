import React from "react";
import styled from "styled-components";
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBBtn,
    MDBModalDialog,
    MDBModalContent,
    MDBTypography,
} from "mdb-react-ui-kit";
import { MDBSwitch } from "mdb-react-ui-kit";
import { Formik, Form, useField } from 'formik';
import { nanoid } from 'nanoid';

const MDBSwitchWrapper = styled.div`
    .form-check-input[type=checkbox]:checked { background-color: #54b4d3; }
    .form-switch .form-check-input:checked[type=checkbox]:after { background-color: #54b4d3; }
`;

const SwitchInput = ({ label, ...props }) => {
    const [field] = useField({ type: "checkbox", ...props });
    return (
        <MDBSwitchWrapper>
            <MDBSwitch {...field} id={nanoid()} label={label} />
        </MDBSwitchWrapper>
    );
};

const FilterSelectorModal = ({ isOpen, setIsOpen, filter, setFilter }) => {
    const handleClose = () => setIsOpen(false);

    return (
        <MDBModal show={isOpen} setShow={setIsOpen} tabIndex="-1">
            <MDBModalDialog size="md">
                <MDBModalContent>
                    <MDBModalHeader>
                        Filter by Signals
                        <MDBBtn className="btn-close" color="none" onClick={handleClose} />
                    </MDBModalHeader>
                    <MDBModalBody className="mt-2">
                        <Formik
                            initialValues={{
                                checked: filter ? filter.split(",") : [],
                            }}
                            onSubmit={(values) => {
                                values.checked.length
                                    ? setFilter(values.checked.join(","))
                                    : setFilter("");
                                handleClose();
                            }}
                            onReset={() => {
                                setFilter("");
                                handleClose();
                            }}
                        >
                            {({ handleReset }) => {
                                return (
                                    <Form>
                                        <div className="mb-4">
                                            <MDBTypography tag="strong">Reference analysis</MDBTypography>
                                            <SwitchInput name="checked" value="da" label="DOI analysis" />
                                            <SwitchInput name="checked" value="rw" label="Retraction Watch" />
                                            <MDBTypography tag="strong">PubPeer</MDBTypography>
                                            <SwitchInput name="checked" value="pm" label="PubPeer hits" />
                                            <MDBTypography tag="strong">ClearSkies Papermill Alarm</MDBTypography>
                                            <SwitchInput name="checked" value="ps" label="Papermill similarity" />
                                            <MDBTypography tag="strong">Text analysis</MDBTypography>
                                            <SwitchInput name="checked" value="tph" label="Tortured phrases" />
                                            <SwitchInput name="checked" value="ut" label="Unnatural text" />
                                            <MDBTypography tag="strong">Watchlist</MDBTypography>
                                            <SwitchInput name="checked" value="aff" label="Fake affiliation names" />
                                            <SwitchInput name="checked" value="idd" label="Fake names or e-mail addresses" />
                                            <SwitchInput name="checked" value="em" label="Fake e-mail domains" />
                                            <SwitchInput name="checked" value="ded" label="Disposable e-mail domains" />
                                        </div>
                                        <MDBModalFooter className="mt-3 ">
                                            <MDBBtn size="md" type="submit" >
                                                Set
                                            </MDBBtn>
                                            <MDBBtn
                                                size="md"
                                                color="danger"
                                                type="submit"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </MDBBtn>
                                        </MDBModalFooter>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default FilterSelectorModal;
