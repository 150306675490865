import axios from 'axios';
import { token as TOKEN, apiBaseUrl } from '../constants';


const axiosInstance = axios.create({
    baseURL: apiBaseUrl
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

export { axiosInstance };