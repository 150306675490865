import React, { useState, useEffect } from 'react';
import "./Modal.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink
} from "mdb-react-ui-kit";
import { convertToNumber } from '../../helpers/helpers';
import FullTextTab from '../Tabs/FullTextTab';
import MetadataTab from '../Tabs/MetadataTab';
import ReferencesTab from '../Tabs/ReferencesTab';
import ModalHeader from './ModalHeader';
import { isEmptyObject } from '../../helpers/helpers';

export const markToHref = signals => {
  let result = signals.map(signal => {
    if (signal?.sentence?.length) {
      let sentence = signal.sentence.map(element => {
        element = element.replace('<mark>', `<a href="https://pubpeer.com/search?q=${encodeURIComponent(signal.pattern)}" target="_blank">`);
        element = element.replace('</mark>', '<i className="ps-1 fa fa-external-link-alt fa-sm"></i></a>');
        return element;
      });
      return { ...signal, sentence }
    }
    return { ...signal }
  });
  return result;
};

const Modal = ({ isOpen, setIsOpen, tab, data, onDeleteEvent, onLoadReport, eventDeleting }) => {
  const [tabId, setTabId] = useState("references");

  const { documentTitle, clearSkiesStatus, signals, gptDetectorScore, feetOfClayRatio, refIdentifiers } = data;
  const title = documentTitle || '';
  const tortured_phrases = signals !== null ? markToHref(signals || []) : null;
  const aiScore = gptDetectorScore !== null ? convertToNumber(gptDetectorScore) : 'N/A';
  const text = clearSkiesStatus
    ? !isEmptyObject(clearSkiesStatus)
      ? clearSkiesStatus[0].toUpperCase() + clearSkiesStatus.substring(1)
      : 'none'
    : 'N/A';
  const suspects = data?.suspects ?? null;
  const referencesRetractions = feetOfClayRatio ?? null;
  const crossref = data?.crossref;


  const renderSwitch = tabId => {
    switch (tabId) {
      case 'references':
        return (
          <ReferencesTab
            crossref={crossref}
            refIdentifiers={refIdentifiers}
            referencesRetractions={referencesRetractions}
          />
        );
      case 'full_text':
        return (
          <FullTextTab
            text={text}
            tortured_phrases={tortured_phrases}
            aiScore={aiScore}
          />
        );
      case 'metadata':
        return (
          <MetadataTab suspects={suspects} />
        );
      default:
        return null;
    };
  };

  useEffect(() => {
    setTabId(tab);
  }, [tab]);

  return (
    <MDBModal show={isOpen} setShow={setIsOpen}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBBtn className='btn-close' color='none' onClick={() => setIsOpen(prev => !prev)} />
          </MDBModalHeader>
          <MDBModalBody className="m-3">
            <MDBRow>
              <MDBCol size="12">
                <ModalHeader
                  title={title}
                  onLoadReport={onLoadReport}
                  eventDeleting={eventDeleting}
                  onDeleteEvent={onDeleteEvent}
                />
                <MDBTabs className='mb-3'>
                  <MDBTabsItem onClick={() => setTabId('references')}>
                    <MDBTabsLink active={tabId === 'references'}>
                      References
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem onClick={() => setTabId('full_text')}>
                    <MDBTabsLink active={tabId === 'full_text'}>
                      Text
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem onClick={() => setTabId('metadata')}>
                    <MDBTabsLink active={tabId === 'metadata'}>
                      Papermill watch list
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
                {renderSwitch(tabId)}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal >
  );
};


export default Modal;

