import {
    notificationsTime,
    token as TOKEN,
    errorMessage as defaultErrorMessage,
    successMessage as defaultSuccessMessage,
} from '../constants';
import { toast } from 'react-toastify';

export const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const checkIsAdmin = () => {
    const token = parseJwt(localStorage.getItem(TOKEN));
    return token && token['cognito:groups']?.includes('Administrators');
};

export const truncateDecimals = function (number, digits) {
    var multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    return truncatedNum / multiplier;
};

export const truncateTitle = (str, maxLength, separator = '\u2026') => {
    if (str.length <= maxLength) return str;
    const sepLength = separator.length,
        charsToShow = maxLength - sepLength,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);
    return str.substr(0, frontChars) + separator + str.substr(str.length - backChars);
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const convertToNumber = value => isNaN(value) ? parseFloat(value) : value;

export const convertToScore = value => Math.round(value * 100);

export const roundToTwo = n => +(Math.round(n + "e+2") + "e-2");

export const isInRange = (num, num1, num2) => Math.min(num1, num2) <= num && Math.max(num1, num2) >= num;

export const isEmptyObject = o => o && Object.keys(o).length === 0 && o.constructor === Object;

export const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});

export const showSuccessToast = (successMessage = defaultSuccessMessage) => {
    return toast.success(successMessage, {
        position: "top-right",
        autoClose: notificationsTime,
    });
};

export const showErrorToast = (errorMessage = defaultErrorMessage) => {
    return toast.error(errorMessage, {
        position: "top-right",
        autoClose: notificationsTime,
    });
};

export const showInfoToast = (message, timeOut = false) => {
    return toast.info(message, {
        position: "top-right",
        autoClose: timeOut,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: false,
    });
};

export const clearToasts = () => {
    return toast.dismiss();
};

export const countProgressValue = score => {
    if (isInRange(score, 0, 25))
        return 25
    else if (isInRange(score, 25, 50))
        return 50
    else if (isInRange(score, 50, 75))
        return 75
    else if (isInRange(score, 75, 100))
        return 100
    else
        return 0
};

export const countProgressColor = value => {
    switch (value) {
        case 25:
            return "success"
        case 50:
            return "danger"
        case 75:
            return "danger"
        case 100:
            return "danger"
        default:
            return "white"
    };
};

export const markToHref = signals => {
    let result = signals.map(signal => {
        let sentence = signal.sentence.map(element => {
            element = element.replace('<mark>', `<a href="https://pubpeer.com/search?q=${encodeURIComponent(signal.pattern)}" target="_blank">`);
            element = element.replace('</mark>', '<i className="ps-1 fa fa-external-link-alt fa-sm"></i></a>');
            return element;
        });
        return { ...signal, sentence }
    });
    return result;
};

