import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";

const TooltipButton = ({
  manuscript,
  onViewReport,
  onViewOldReport,
  setIsWatchlistModalOpen,
}) => {
  // const showActions = Math.random() < 0.2;

  // if (!showActions) {
  //   return null;
  // }
  // duplicate-submission

  const showDuplicateSubmissionIcon = manuscript?.signals?.find(
    (x) => x === "duplicate-submission"
  );
  // const showWatchlistIcon = Math.random() < 0.5;
  // const showReferencesIcon = Math.random() < 0.5;
  // const showScreeningIcon = Math.random() < 0.5;
  const showWatchlistIcon = false;
  const showReferencesIcon = false;
  const showScreeningIcon = false;

  return (
    <div className="d-flex">
      {showDuplicateSubmissionIcon && (
        <MDBTooltip
          tag="div"
          title="Match in Duplicate submissions, click for report"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="primary"
            floating
            onClick={() => onViewReport(manuscript.publisher_manuscript_id)}
          >
            <MDBIcon fas icon="copy" />
          </MDBBtn>
        </MDBTooltip>
      )}
      {showWatchlistIcon && (
        <MDBTooltip
          tag="div"
          title="Watchlist match found, click for details"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="secondary"
            floating
            onClick={() => setIsWatchlistModalOpen(true)}
          >
            <MDBIcon far icon="eye" />
          </MDBBtn>
        </MDBTooltip>
      )}
      {showReferencesIcon && (
        <MDBTooltip
          tag="div"
          title="Suspicious references, click for report"
          placement="top"
        >
          <MDBBtn className="mx-2" color="warning" floating>
            <MDBIcon fas icon="exclamation-triangle" />
          </MDBBtn>
        </MDBTooltip>
      )}
      {showScreeningIcon && (
        <MDBTooltip
          className="mx-2"
          tag="div"
          title="On-demand Screening report available, click to open"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="danger"
            floating
            onClick={onViewOldReport}
          >
            <MDBIcon fas icon="search" />
          </MDBBtn>
        </MDBTooltip>
      )}
    </div>
  );
};

export { TooltipButton };
