import React from 'react';
import { MDBTypography } from 'mdb-react-ui-kit';

const DataField = ({ title, items, viewDetails }) => {
  return (
    <div className="data_field">
      <div className="d-flex align-items-center mb-2">
        {title}
      </div>
      {
        items.map((item, index) => (
          <div key={index} className='d-flex align-items-center'>
            <div className='d-flex' style={{ cursor: 'pointer' }} onClick={viewDetails}>
              <MDBTypography variant='p' className='mb-1' colorText='blue' key={index}>
                <small style={{ color: item?.labelColor }}>
                  {item.label}
                  {item?.value
                    ? <span className='ms-1' style={{ color: 'indigo' }}>
                      {item.value}
                    </span>
                    : null
                  }
                </small>
              </MDBTypography>
              {
                item?.labelColor
                  ? <span><i style={{ color: item?.labelColor }} className="fa fa-exclamation-circle ms-1"></i></span>
                  : null
              }
            </div>
            {item.popover}
          </div>
        ))
      }
    </div >
  );
};

export default DataField;
