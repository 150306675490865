import { axiosInstance } from './axiosSetup';
import axios from 'axios';
import { handleErrors } from './handleErrors';
import { isNumeric } from './helpers';
import { decode } from 'html-entities';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const authorize = credentials => {
    return axiosInstance
        .post('authorize', credentials);
};

export const getWsToken = async () => {
    return axiosInstance
        .get('/papermill/wstoken')
        .catch(handleErrors)
};

export const getComponents = async () => {
    return axiosInstance
        .get('/user')
        .catch(handleErrors)
};

export const getEventByPersistentId = async (id) => {
    return { data: getEventByPersistentIdMockResponse }
    // return axiosInstance
    //     .get(`find_reports/${encodeURIComponent(id)}`)
    //     .catch(handleErrors)
};

export const uploadFile = async (file) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post('papermill/initiate', { document: file.name })
            .then(({ data: { s3PutObjectUrl } }) => {
                axios.put(s3PutObjectUrl, file);
                resolve(true);
            })
            .catch((error) => {
                handleErrors(error);
                reject(error);
            });
    });
};

export const getEvents = async (offset, perPage, search, filter) => {
    return { data: mockEventsResponse }
    // return axiosInstance
    //     .get(`papermill/events?startrow=${offset}&maxrows=${perPage}&searchq=${encodeURIComponent(search)}&filter=${filter}`)
    //     .catch(handleErrors)
};

export const exportEvents = async () => {
    return axiosInstance
        .get('papermill/events/export')
        .catch(handleErrors)
};

export const deleteEvent = async (key) => {
    const encoded = encodeURIComponent(key);
    return axiosInstance
        .delete(`papermill/events?keys=${encoded}`)
        .catch(handleErrors)
};

export const formatEvent = event => {
    const {
        documentName,
        externalPublisherId,
        documentTitle,
        refIdentifiers,
        signals,
        feetOfClayRatio: foc,
        clearSkiesStatus: similarity,
        gptDetectorScore: unnatural,
        crossref,
        suspects
    } = event;
    const { extracted, invalid, parsed } = refIdentifiers;
    const { eoc, retracted, withdrawn } = crossref || {};
    const { retracted: fRetracted, details } = foc || {};
    const { affiliations, emails, tempmails } = suspects;

    let formatted = {
        header: {
            'External publisher id': documentName || externalPublisherId,
            'Document title': documentTitle,
        },
        analysis: {
            'DOI analysis:': '',
            'References marked as \'expression of concern\'': !crossref
                ? 'N/A'
                : eoc?.length
                    ? eoc
                    : 'None found',
            'References marked as \'retracted\'': !crossref
                ? 'N/A'
                : retracted?.length
                    ? retracted
                    : 'None found',
            'References marked as \'withdrawn\'': !crossref
                ? 'N/A'
                : withdrawn?.length
                    ? withdrawn
                    : 'None found',
        },
        mentions: {
            'PubPeer mentions:': '',
            'References retracted': !foc
                ? 'N/A'
                : fRetracted?.length
                    ? fRetracted
                    : 'None found',
            'Mentions': !foc
                ? 'N/A'
                : details?.length
                    ? details.map(item => `${item.doi} [${item.comments}]`)
                    : 'None found',
        },
        doi: {
            'Number of listed DOIs that do not exist': invalid?.length && invalid.length > 0
                ? '' + invalid.length
                : '' + (invalid?.length ?? '-'),
            'Total number of references listed in manuscript': '' + (parsed ?? '-'),
            'From which DOIs were listed or could be retrieved': '' + (extracted ?? '-')
        },
        text: {
            'Papermill similarity': similarity !== null ? capitalized(similarity) : 'N/A',
            'Tortured phrases': !signals
                ? 'N/A'
                : Array.isArray(signals) && signals.length
                    ? signals.map(item => item.pattern)
                    : 'Not found',
            'Unnatural text': isNumeric(unnatural) ? unnatural + '%' : 'N/A',
        },
        metadata: {
            'Papermill watch list:': '',
            'Fake affiliation names': !affiliations
                ? 'N/A'
                : affiliations?.length
                    ? affiliations
                    : 'None found',
            'Fake e-mail domains': !emails
                ? 'N/A'
                : emails?.length
                    ? affiliations
                    : 'None found',
            'Disposable e-mail domains:': !tempmails
                ? 'N/A'
                : tempmails?.length
                    ? tempmails
                    : 'None found'
        }
    };

    return formatted;
};

export const genReport = async (event) => {
    const doc = new jsPDF();
    let formatted = formatEvent(event, false);

    const autoTableStyle = {
        columnStyles: { 0: { cellWidth: 70 }, 1: { cellWidth: 'auto' } },
        bodyStyles: {
            valign: 'middle',
            fillColor: [236, 236, 236],
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255]
        }
    }

    doc.setFontSize(16).text('Report', 15, 15);
    let body = tableBody(formatted.header);
    doc.autoTable({
        ...autoTableStyle,
        startY: 20,
        body
    });

    let finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('References', 15, finalY + 10);
    finalY = doc.previousAutoTable.finalY;
    body = tableBody(formatted.analysis);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    body = tableBody(formatted.mentions);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 3,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    body = tableBody(formatted.doi);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 3,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('Text', 15, finalY + 10);
    body = tableBody(formatted.text);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('Papermill watch list', 15, finalY + 10);
    body = tableBody(formatted.metadata);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    const blob = new Blob([doc.output()], { type: 'application/pdf' })
    return blob;
};

const tableBody = obj => {
    let rows = Object.entries(obj);
    return rows.map(row => {
        if (Array.isArray(row[1])) {
            row[1] = row[1].join('\n');
        }
        row[1] = decode(row[1]);
        return row;
    });
}

const capitalized = word => word.charAt(0).toUpperCase() + word.slice(1);


const mockEventsResponse = ({
    "events": [
        {
            "GSI4SK": "retracted article: bilobalide protects h9c2 cell from oxygen-glucose-deprivation-caused damage via upregulation of mir-27a",
            "SK": "PAPERMILL#2024-03-19T08:49:32.118Z",
            "GSI7SK": "1a963a43-094b-406b-a00c-7e35c8d7ee1f",
            "PK": "PARTICIPANT#appetence_-MZyJc",
            "Attrs": {
                "refIdentifiers": {
                    "valid": 45,
                    "invalid": [],
                    "parsed": 50,
                    "unidentified": [
                        {
                            "title": "Evolving therapies for myocardial ischemia/reperfusion injury"
                        },
                        {
                            "title": "MicroRNA-15a/b are up-regulated in response to myocardial ischemia/reperfusion injury"
                        },
                        {
                            "title": "Protective mechanisms of microRNA-27a against oxygen-glucose deprivation-induced injuries in hippocampal neurons"
                        },
                        {
                            "title": "Reactive oxygen species-induced apoptosis in PC12 cells and protective effect of bilobalide"
                        },
                        {
                            "title": "Protective effects of bilobalide on amyloid beta-peptide 25-35-induced PC12 cell cytotoxicity"
                        }
                    ],
                    "extracted": 45
                },
                "feetOfClayRatio": null,
                "gptDetectorScore": 0,
                "documentName": "RETRACTED ARTICLE Bilobalide protects H9c2 cell from oxygen glucose deprivation caused damage via upregulation of miR 27a.pdf",
                "suspects": {
                    "emails": [],
                    "affiliations": null,
                    "identities": null,
                    "tempmails": []
                },
                "participant": "1a963a43-094b-406b-a00c-7e35c8d7ee1f",
                "signals": null,
                "logstreamtoken": "49649988922500229063834115158417848636177631195160379570",
                "retractionWatch": [
                    {
                        "title": "Ginsenoside Rg3 inhibits cell growth, migration and invasion in Caco-2 cells by downregulation of lncRNA CCAT1",
                        "DOI": "10.1016/j.yexmp.2019.01.003"
                    },
                    {
                        "title": "MicroRNA-132 protects hippocampal neurons against oxygen-glucose deprivation induced apoptosis",
                        "DOI": "10.1177/0394632017715837"
                    }
                ],
                "signalsScore": null,
                "documentId": "c1cbbf5d-60a4-4cc7-ce6f-fe61afe59cbf",
                "logstream": "d8f3ab6af7484635908b10946607897c",
                "documentTitle": "RETRACTED ARTICLE: Bilobalide protects H9c2 cell from oxygen-glucose-deprivation-caused damage via upregulation of miR-27a",
                "username": "papermill@appetence.nl",
                "clearSkiesStatus": null
            }
        }
    ],
    "total": 1
});


const getEventByPersistentIdMockResponse =
{
    "id": 38721,
    "controller_manuscript_id": "9aae295c-938b-4ad6-9d37-b45d433f3185",
    "report_timestamp": "2024-03-04T08:44:40.979Z",
    "publisher_id": 16,
    "persistent_id": "ieee-07YkS-53846340",
    "message": {
        "form": "operational",
        "index": "under_review",
        "action": "addrunreport",
        "authors": [
            {
                "email": "a.alsohaibani@qu.edu.sa",
                "lastname": "ALSOHAIBANI",
                "firstname": "ALI",
                "affiliation": []
            },
            {
                "email": "kgaanoun@insea.ac.ma",
                "lastname": "GAANOUN",
                "firstname": "KAMEL",
                "affiliation": []
            },
            {
                "email": "m.suhibani@qu.edu.sa",
                "lastname": "Alsuhaibani",
                "firstname": "Mohammed",
                "middlename": "",
                "affiliation": []
            }
        ],
        "abstract": "Most neural machine translation (NMT) systems rely on parallel data, comprising text in the source language and its corresponding translation in the target language. However, we argue, incorporating contextual elements from the source language may enhance the performance of these systems. This parallels the way in which human translators, interpreters, and other language professionals use context to better understand the text. To enable NMT models to emulate this process, we propose to adopt state-of-the-art NMT systems and incorporate context represented by explanations of meanings into the source language text. Similar to how classic literature benefits from scholarly interpretation, our approach provides an enriched layer of context for complex information. Upon conducting the experiment, we have substantiated that providing additional information in this manner yields an enhancement in the performance of existing NMT systems, surpassing the baseline model by a considerable margin ranging from 0.46 to 1.87 BLEU score points. This advancement can be attributed to the adept utilization of state-of-the-art Natural Language Processing (NLP) techniques, specifically transformer-based models.",
        "publisher_id": 16,
        "connectorType": "s1",
        "journal_title": "IEEE Access",
        "persistent_id": "ieee-07YkS-53846340",
        "feedback_email": "similaritycheck@ieee.org",
        "publisher_name": "ieee",
        "manuscript_title": "Transformer Meets External Context: A Novel Approach to Enhance Neural Machine Translation",
        "uploadedFilesS3Keys": [],
        "publisher_manuscript_id": "Access-2024-08650"
    },
    "index_entry_id": "1709541881138",
    "status": "INDEXED",
    "normalized_search_terms": "ieee-07yks-53846340 access-2024-08650 ieee access transformer meets external context: a novel approach to enhance neural machine translation"

}